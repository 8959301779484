<template>
  <div class="relative-form-div">
    <div class="mt-2">
      <DXATextField
        :Field="$t('code')"
        :Text="code"
        @update="
          (value) => {
            code = value;
          }
        "
        :ErrorText="errorCode"
        class="my-4"
        data-test="Banker:CheckRegisterCode:InputCode"
      />
    </div>
    <v-row
      :class="gs.isMobile() ? 'mt-0' : 'mt-13'"
      :style="`flex-direction: ${gs.isMobile() ? 'row-reverse' : 'column'};`"
    >
      <v-col
        @click="send_another_code()"
        cols="6"
        md="12"
        class="pt-0"
        :style="`display: flex; justify-content: ${
          gs.isMobile() ? 'end' : 'start'
        }; align-items: center`"
      >
        <a class="options-btn">{{ $t("resent_code") }}</a>
      </v-col>
      <v-col
        @click="$emit('edit_info')"
        cols="6"
        md="12"
        class="pt-4"
        style="display: flex; justify-content: start; align-items: center"
      >
        <a class="options-btn" @click="$emit('edit_info')">{{
          $t("edit_information_btn")
        }}</a>
      </v-col>
    </v-row>
    <v-row
      justify="start"
      :class="`ma-0 mt-2 mb-3 ${gs.isMobile() ? '' : 'bottom-row'}`"
    >
      <v-col cols="12" md="4">
        <v-btn
          data-test="Banker:CheckRegisterCode:NextStep"
          @click="check_form"
          :loading="loading"
          class="mx-0 my-1 mb-3 dxa-btn-primary-blue"
          block
          style="height: 45px !important"
          ><span style="font-size: 24px">{{ $t("next") }}</span></v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.options-btn {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: #1a7dff;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DXATextField from "@/components/dxa/TextField/DxaTextField";
import mixpanel from "mixpanel-browser";

export default {
  name: "CheckRegisterCode",
  components: { DXATextField },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: true,
    lazy: false,
    alert: null,
    code: null,
    loading: false,
    errorCode: null,
  }),
  async created() {},
  props: {
    User: Object,
    LoginInfo: String,
    UserCreatedData: Object,
  },
  computed: {},
  methods: {
    async send_another_code() {
      this.loading = true;
      await this.apiService
        .postRequest("resetcode/send", this.User)
        .then((result) => {
          this.$emit("resent");
        })
        .catch((err) => {
          this.$emit("error", err.body.message);
        });
      this.loading = false;
    },
    async check_form() {
      this.errorCode = null;
      this.loading = true;
      if (this.code) {
        await this.apiService
          .postRequest("resetcode/validate", {
            code: parseInt(this.code),
            userId: this.User.Id,
          })
          .then((result) => {
            this.$router.push(
              `/auth/banker-register-finish?partner=${this.$route.query.partner}&login=${this.LoginInfo}`
            );

            // DISPARANDO O EVENTO CASO A VERIFICAÇÃO DE 200 E O USUÁRIO SEJA CADASTRADO
            mixpanel.track("BankerRegister_Finish", {
              partner_id: this.UserCreatedData.PartnerId,
              user_name: this.UserCreatedData.Name,
              email: this.UserCreatedData.Email,
              phone: this.UserCreatedData.CelPhone,
              nationality: this.UserCreatedData.Nationality,
              user_document_id: this.UserCreatedData.SocialNumber,
              user_type: "banker",
              user_id: this.User.Id,
            });
          })
          .catch((err) => {
            this.$toast.error(err.body.message);
          });
        this.$emit("next");
      } else {
        this.errorCode = this.$t("code_invalid");
      }
      this.loading = false;
    },
  },
};
</script>
