<template>
  <div class="relative-form-div">
    <v-row>
      <v-col cols="12" md="6">
        <DXATextField
          :Field="$t('email')"
          :Text="user.Email"
          :ErrorText="errorEmail"
          @text_changed="check_email()"
          @update="
            (value) => {
              user.Email = value;
            }
          "
          Type="email"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="!gs.isMobile()"> </v-col>
      <v-col cols="12" md="6">
        <DXATextField
          :Field="$t('celphone')"
          :Text="user.PhoneNumber"
          :ErrorText="errorPhone"
          @text_changed="check_phone()"
          @update="
            (value) => {
              user.PhoneNumber = value;
            }
          "
          Mask="(##) #####-####"
          Type="tel"
        />
      </v-col>
      <v-col cols="12" md="6" v-if="!gs.isMobile()"> </v-col>
    </v-row>
    <v-row
      :justify="gs.isMobile() ? 'center' : 'start'"
      align="center"
      :class="`ma-0 mt-2 mb-3 ${gs.isMobile() ? '' : 'bottom-row'}`"
    >
      <v-col cols="12" md="3">
        <v-btn
          @click="check_form"
          :loading="loading"
          class="mx-0 my-1 dxa-btn-primary-blue"
          block
          style="height: 45px !important"
          ><span style="font-size: 24px">{{ $t("save") }}</span></v-btn
        >
      </v-col>
      <v-col
        cols="12"
        md="3"
        :class="`text-${gs.isMobile() ? 'center' : 'start'}`"
      >
        <a
          @click="$emit('cancel')"
          style="
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            text-decoration-line: underline;
            color: #1a7dff;
          "
          >{{ $t("cancel") }}</a
        >
      </v-col>
    </v-row>
    <v-alert class="ma-2" v-if="fieldError" type="error">
      {{ fieldError }}
    </v-alert>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationService from "@/services/ValidationService";
import DXATextField from "@/components/dxa/TextField/DxaTextField";
import moment from "moment";
export default {
  name: "Editnformation",
  components: { DXATextField },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    vs: new ValidationService(),
    valid: true,
    lazy: false,
    alert: null,
    errorEmail: null,
    errorPhone: null,
    loading: false,
    user: {},
  }),
  async created() {
    this.user = JSON.parse(JSON.stringify(this.User));
  },
  props: {
    User: Object,
  },
  computed: {
    fields_filled() {
      return this.User.Email && this.User.PhoneNumber;
    },
    errors() {
      return this.errorEmail || this.errorPhone;
    },
  },
  methods: {
    check_phone() {
      this.errorPhone = null;
      if (!this.vs.phone_valid(this.user.PhoneNumber)) {
        this.errorPhone = this.$t("phone_invalid");
      }
    },
    async check_email() {
      this.errorEmail = null;
      if (!this.vs.accent_valid(this.user.Email)) {
        this.errorEmail = this.$t("accent_char");
        return;
      }
      if (!this.vs.email_valid(this.user.Email)) {
        this.errorEmail = this.$t("format_invalid");
        return;
      }
      if (
        await this.vs.check_if_email_exists(
          this.user.Id,
          this.user.Email,
          false
        )
      ) {
        this.errorEmail = this.$t("user_exists");
      }
    },
    async check_form() {
      this.loading = true;
      this.fieldError = null;
      if (this.fields_filled && !this.errors) {
        await this.apiService
          .putRequest("banker/change-information", this.user)
          .then((result) => {
            this.$emit("update", JSON.parse(result.message));
          })
          .catch((err) => {
            this.$emit("error", err.body.message);
          });
      } else if (!this.fields_filled) {
        this.fieldError = this.$t("all_fields_required");
      }
      this.loading = false;
    },
  },
};
</script>
