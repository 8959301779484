import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height justify-start pa-0",attrs:{"id":"register"}},[_c('div',{staticClass:"dxa-form-page-div"},[_c('div',{staticClass:"outside-information-div"},[(_vm.editInfo)?_c('p',{staticClass:"dxa-outside-header",staticStyle:{"color":"var(--white)"}},[_vm._v(" "+_vm._s(_vm.$t("edit_information"))+" ")]):_c('div',[_c('p',{staticClass:"dxa-outside-header",staticStyle:{"color":"var(--white)"}},[_vm._v(" "+_vm._s(_vm.$t(("banker_register_step_" + _vm.step + "_header")))+" ")]),(_vm.step != 2 || !_vm.gs.isMobile())?_c('p',{staticClass:"dxa-outside-subheader",staticStyle:{"color":"var(--white)"}},[_vm._v(" "+_vm._s(_vm.$t(("banker_register_step_" + _vm.step + "_subheader")))+" ")]):_c('p',{staticClass:"dxa-outside-subheader",staticStyle:{"color":"var(--white)"},domProps:{"innerHTML":_vm._s(
            _vm.$t("banker_register_step_2_outside_header", {
              email: _vm.user.Email,
              celPhone: _vm.user.CelPhone,
            })
          )}})])])]),(!_vm.gs.isMobile())?_c('div',[_c('div',{staticClass:"banker-card-div"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pl-3",staticStyle:{"border-radius":"12px"},attrs:{"cols":"12","md":"8"}},[_c('h3',{staticClass:"dxa-upper-card-header",staticStyle:{"color":"var(--white)"}},[_vm._v(" "+_vm._s(_vm.$t("alpha_register_step_count", { step: _vm.step, totalSteps: 2 }))+" ")]),_c(VProgressLinear,{staticClass:"dxa-progress-bar",staticStyle:{"margin-bottom":"-4px"},attrs:{"color":"#1a7dff","height":"4","value":(_vm.step * 100) / 2}})],1)],1)],1),_c(VSlideYTransition,{attrs:{"appear":""}},[_c(VCard,{staticClass:"dxa-card",attrs:{"light":"","color":"white"}},[_c(VRow,{staticClass:"dxa-card-row",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"dxa-card-col-8",attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"pa-2 pt-0",class:("text-" + (_vm.gs.isMobile() ? 'center' : 'left')),staticStyle:{"height":"100%"}},[(!_vm.editInfo && _vm.step == 2)?_c('div',{class:"pr-12"},[_c('h5',{staticClass:"subheader mb-1 mt-6",domProps:{"innerHTML":_vm._s(
                    _vm.$t("banker_register_step_2_outside_header", {
                      email: _vm.user.Email,
                      celPhone: _vm.user.CelPhone,
                    })
                  )}})]):_vm._e(),(_vm.step == 1)?_c('BankerInformation',{attrs:{"User":_vm.user},on:{"created":function (value, userData) {
                    _vm.user = value;
                    _vm.step += 1;
                    _vm.userCreatedData = userData;
                  },"error":_vm.error_occoured}}):(_vm.step == 2 && _vm.editInfo)?_c('EditInformation',{attrs:{"User":_vm.user},on:{"error":_vm.error_occoured,"update":_vm.information_updated,"cancel":function($event){_vm.editInfo = false}}}):(_vm.step == 2)?_c('CheckRegisterCode',{attrs:{"User":_vm.user,"LoginInfo":_vm.user.LoginEncrypted,"UserCreatedData":_vm.userCreatedData},on:{"error":_vm.error_occoured,"edit_info":function($event){_vm.editInfo = true},"resent":_vm.code_resent}}):_vm._e(),(_vm.loading)?_c(VRow,{staticClass:"my-3",attrs:{"justify":"center","align":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"15"}})],1):_vm._e()],1),(_vm.step == 1 && _vm.alert)?_c(VAlert,{staticClass:"ma-2",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")]):_vm._e()],1),_c(VCol,{staticClass:"dxa-card-col-4",attrs:{"cols":"0","md":"3"}},[_c('img',{staticClass:"dxa-upper-img",staticStyle:{"opacity":"0"},attrs:{"alt":"DXA","src":require("../../../../../assets/dxa-x-blue.svg")}}),_c('img',{staticClass:"dxa-lower-img",attrs:{"alt":"Icon","src":require("../../../../../assets/dxa-stars-background.png")}})])],1)],1)],1)],1):_c('div',{staticClass:"px-4"},[(_vm.step == 1)?_c('BankerInformation',{attrs:{"User":_vm.user},on:{"created":function (value, userData) {
          _vm.user = value;
          _vm.step += 1;
          _vm.userCreatedData = userData;
        },"error":_vm.error_occoured}}):(_vm.step == 2 && _vm.editInfo)?_c('EditInformation',{attrs:{"User":_vm.user},on:{"update":_vm.information_updated,"error":_vm.error_occoured,"cancel":function($event){_vm.editInfo = false}}}):(_vm.step == 2)?_c('CheckRegisterCode',{attrs:{"User":_vm.user,"UserCreatedData":_vm.userCreatedData},on:{"edit_info":function($event){_vm.editInfo = true},"resent":_vm.code_resent,"error":_vm.error_occoured}}):_vm._e(),(_vm.loading)?_c(VRow,{staticClass:"my-3",attrs:{"justify":"center","align":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"15"}})],1):_vm._e()],1),(_vm.dialog)?_c(VDialog,{attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AlertModal',{attrs:{"IsError":_vm.error,"Header":_vm.dialogHeader,"Text":_vm.dialogText,"User":_vm.user},on:{"close":_vm.reset_modal}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }