<template>
  <div id="register" class="fill-height justify-start pa-0">
    <div class="dxa-form-page-div">
      <div class="outside-information-div">
        <p
          class="dxa-outside-header"
          v-if="editInfo"
          style="color: var(--white)"
        >
          {{ $t(`edit_information`) }}
        </p>
        <div v-else>
          <p class="dxa-outside-header" style="color: var(--white)">
            {{ $t(`banker_register_step_${step}_header`) }}
          </p>
          <p
            class="dxa-outside-subheader"
            v-if="step != 2 || !gs.isMobile()"
            style="color: var(--white)"
          >
            {{ $t(`banker_register_step_${step}_subheader`) }}
          </p>
          <p
            style="color: var(--white)"
            v-else
            class="dxa-outside-subheader"
            v-html="
              $t(`banker_register_step_2_outside_header`, {
                email: user.Email,
                celPhone: user.CelPhone,
              })
            "
          ></p>
        </div>
      </div>
    </div>
    <div v-if="!gs.isMobile()">
      <div class="banker-card-div">
        <v-row no-gutters>
          <v-col cols="12" md="8" class="pl-3" style="border-radius: 12px">
            <h3 class="dxa-upper-card-header" style="color: var(--white)">
              {{
                $t("alpha_register_step_count", { step: step, totalSteps: 2 })
              }}
            </h3>

            <v-progress-linear
              color="#1a7dff"
              height="4"
              class="dxa-progress-bar"
              style="margin-bottom: -4px"
              :value="(step * 100) / 2"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <v-slide-y-transition appear>
        <v-card light class="dxa-card" color="white">
          <v-row no-gutters class="dxa-card-row">
            <v-col cols="12" md="9" class="dxa-card-col-8">
              <div
                class="pa-2 pt-0"
                style="height: 100%"
                :class="`text-${gs.isMobile() ? 'center' : 'left'}`"
              >
                <div v-if="!editInfo && step == 2" :class="`pr-12`">
                  <h5
                    class="subheader mb-1 mt-6"
                    v-html="
                      $t(`banker_register_step_2_outside_header`, {
                        email: user.Email,
                        celPhone: user.CelPhone,
                      })
                    "
                  ></h5>
                </div>

                <BankerInformation
                  v-if="step == 1"
                  :User="user"
                  @created="
                    (value, userData) => {
                      user = value;
                      step += 1;
                      userCreatedData = userData;
                    }
                  "
                  @error="error_occoured"
                />
                <EditInformation
                  v-else-if="step == 2 && editInfo"
                  :User="user"
                  @error="error_occoured"
                  @update="information_updated"
                  @cancel="editInfo = false"
                />
                <CheckRegisterCode
                  v-else-if="step == 2"
                  :User="user"
                  :LoginInfo="user.LoginEncrypted"
                  @error="error_occoured"
                  @edit_info="editInfo = true"
                  @resent="code_resent"
                  :UserCreatedData="userCreatedData"
                />
                <v-row
                  justify="center"
                  align="center"
                  v-if="loading"
                  class="my-3"
                >
                  <v-progress-circular
                    indeterminate
                    size="15"
                  ></v-progress-circular>
                </v-row>
              </div>
              <v-alert class="ma-2" v-if="step == 1 && alert" type="error">
                {{ alert }}
              </v-alert>
            </v-col>
            <v-col cols="0" md="3" class="dxa-card-col-4">
              <img
                alt="DXA"
                src="../../../../../assets/dxa-x-blue.svg"
                class="dxa-upper-img"
                style="opacity: 0"
              />
              <img
                alt="Icon"
                src="../../../../../assets/dxa-stars-background.png"
                class="dxa-lower-img"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-slide-y-transition>
    </div>
    <div v-else class="px-4">
      <BankerInformation
        v-if="step == 1"
        :User="user"
        @created="
          (value, userData) => {
            user = value;
            step += 1;
            userCreatedData = userData;
          }
        "
        @error="error_occoured"
      />
      <EditInformation
        v-else-if="step == 2 && editInfo"
        :User="user"
        @update="information_updated"
        @error="error_occoured"
        @cancel="editInfo = false"
      />
      <CheckRegisterCode
        v-else-if="step == 2"
        :User="user"
        @edit_info="editInfo = true"
        @resent="code_resent"
        @error="error_occoured"
        :UserCreatedData="userCreatedData"
      />
      <v-row justify="center" align="center" v-if="loading" class="my-3">
        <v-progress-circular indeterminate size="15"></v-progress-circular>
      </v-row>
    </div>
    <v-dialog v-model="dialog" v-if="dialog" persistent>
      <AlertModal
        @close="reset_modal"
        :IsError="error"
        :Header="dialogHeader"
        :Text="dialogText"
        :User="user"
      />
    </v-dialog>
  </div>
</template>

<style lang="scss">
.relative-form-div {
  box-sizing: border-box;
  height: 100%;
}
.bottom-row {
  position: absolute;
  bottom: 5px;
  width: 100%;
}
.dxa-header {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}
.subheader {
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
}
.left-paragraphs {
  text-align: left;
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 0px !important;
}
.crisp-client {
  display: none;
}
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import BankerInformation from "./steps/BankerInformation.vue";
import CheckRegisterCode from "./steps/CheckRegisterCode.vue";
import EditInformation from "./steps/EditInformation.vue";
import { mapGetters } from "vuex";
import AlertModal from "@/components/dxa/Modal/AlertModal";
import { DocumentTypeEnum } from "@/shared/enums/DocumentType.js";
import { bankerRegisterWrongUrl } from "@/shared/helpers/bankerRegisterHelper";

export default {
  name: "BankerRegister",
  components: {
    BankerInformation,
    CheckRegisterCode,
    EditInformation,
    AlertModal,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    valid: false,
    lazy: false,
    loading: false,
    alert: null,
    step: 1,
    dialogText: null,
    dialogHeader: null,
    dialog: false,
    error: false,
    user: {
      Name: null,
      LastName: null,
      SocialNumber: null,
      Email: null,
      CelPhone: null,
      UserPassword: null,
      Terms: false,
      ConfidencialityTerms: false,
      PartnerB2bId: null,
      RegisterDocumentType: 3,
      CountryCode: "55",
      Nationality: "Brazil",
    },
    editInfo: false,
    userCreatedData: null,
    documentTypeEnum: DocumentTypeEnum,
  }),
  watch: {
    step() {
      this.$store.commit("SET_STEP", this.step);
    },
  },
  async created() {
    this.getFormData();

    var partnerUrl = this.$route.query.partner;
    if (partnerUrl) {
      if (!this.configs) {
        await this.apiService
          .getRequest(`partner/get/${partnerUrl}`)
          .then((result) => {
            let resultObj = JSON.parse(result);
            this.$store.commit("SET_PARTNER", resultObj);
            this.user.PartnerB2bId = resultObj.Id;
          })
          .catch((err) => {
            if (err.status === 400) {
              bankerRegisterWrongUrl();
            }
          });
      }
      this.user.PartnerB2bId = this.configs.Id;
      if (!this.user.PartnerB2bId) {
        this.$router.replace({ query: { partner: undefined } });
      }
    } else {
      bankerRegisterWrongUrl();
    }
  },
  computed: {
    ...mapGetters({
      configs: "get_partner",
    }),
  },
  methods: {
    error_occoured(error) {
      this.dialogHeader = this.$t(error);
      this.dialogText = this.$t(`${error}_text`);
      this.error = true;
      this.dialog = true;
    },
    code_resent() {
      this.dialogHeader = this.$t("code_resent");
      this.dialogText = this.$t("code_resent_text");
      this.dialog = true;
    },
    reset_modal() {
      this.dialog = false;
      this.dialogText = null;
      this.dialogHeader = null;
      this.error = false;
    },
    information_updated(value) {
      this.user = value;
      this.editInfo = false;
      this.dialogHeader = this.$t("code_resent");
      this.dialogText = this.$t("edit_information_successful");
      this.dialog = true;
    },
    goFoward() {
      this.step += 1;
    },
    goBack() {
      this.step -= 1;
    },
    getFormData() {
      const bankerFormDataLocalStorage = localStorage.getItem("bankerFormData");
      if (bankerFormDataLocalStorage) {
        const bankerFormData = JSON.parse(bankerFormDataLocalStorage);

        if (bankerFormData["form_data"]) {
          const decodedData = Buffer.from(
            bankerFormData["form_data"],
            "base64"
          ).toString();

          const parsedDecodedData = JSON.parse(decodedData);

          this.user.Name = parsedDecodedData.firstName;
          this.user.LastName = parsedDecodedData.lastName;
          this.user.SocialNumber = parsedDecodedData.documentNumber;
          this.user.Email = parsedDecodedData.email;
          this.user.CountryCode = parsedDecodedData.countryCode;
          this.user.CelPhone = parsedDecodedData.phone;
          this.user.Nationality = parsedDecodedData.nationality;
          this.user.RegisterDocumentType = this.parseDocumentType(
            parsedDecodedData.documentType
          );
        }
      }
    },
    parseDocumentType(document) {
      const docTypes = [
        { text: "cpf", value: this.documentTypeEnum.socialNumber },
        { text: "passport", value: this.documentTypeEnum.passport },
      ];

      return docTypes.filter((type) => type.text == (document || "cpf"))[0]
        .value;
    },
  },
};
</script>
<style scoped>
.lessThanEight {
  margin-top: 45px;
}
.OnlyEight {
  margin-top: 0px;
}
.lessThan {
  background-color: var(--white);
  min-height: 725px;
}
.eight {
  background-color: rgba(255, 255, 255, 0);
}

.banker-card-div {
  width: 95%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.dxa-card {
  width: 95% !important;
}
</style>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
