<template>
  <div class="text-left">
    <div class="mt-2">
      <v-row justify="start" align="center">
        <v-col cols="12" md="6">
          <DXATextField
            data-test="banker:signup:first_name"
            :Field="$t('name')"
            :Text="User.Name"
            @update="
              (value) => {
                User.Name = value;
              }
            "
            class="customTextField"
          />
        </v-col>
        <v-col cols="12" md="6">
          <DXATextField
            data-test="banker:signup:last_name"
            :Field="$t('last_name')"
            :Text="User.LastName"
            @update="
              (value) => {
                User.LastName = value;
              }
            "
            class="customTextField"
          />
        </v-col>
        <v-col ccols="12" md="6">
          <div :style="`height: ${FullHeight ? '80%' : 'auto'}`">
            <p
              id="label"
              class="dxa-text-field-label mb-1"
              :style="`color: ${color}`"
            >
              {{ $t("nationality") }}
            </p>
            <v-select
              data-test="banker:signup:nationality"
              :rules="[required]"
              :items="countries"
              background-color="#e9e9e9"
              dense
              filled
              item-text="name"
              return-object
              @change="(e) => nationality_code_changed(e)"
              v-model="nationalitySelected"
              class="custom-select"
            >
              <template slot="selection" slot-scope="data">
                <img
                  :src="data.item.flag"
                  style="width: 18px; height: auto"
                  alt=""
                />
                <span class="text-combo-box mx-2">{{ data.item.name }}</span>
              </template>
              <template slot="item" slot-scope="data">
                <img
                  :src="data.item.flag"
                  style="width: 18px; height: auto"
                  alt=""
                />
                <span class="mx-2">{{ data.item.name }}</span>
              </template></v-select
            >
          </div>
        </v-col>

        <v-col cols="12" md="6"></v-col>

        <v-col cols="12" md="6">
          <div :style="`height: ${FullHeight ? '80%' : 'auto'}`"></div>
          <p
            id="label"
            class="dxa-text-field-label mb-1"
            :style="`color: ${color}`"
          >
            {{ $t("document_type") }}
          </p>
          <v-radio-group
            v-model="User.RegisterDocumentType"
            style="margin-top: 4px"
            row
          >
            <v-radio
              style="display: flex; align-itens: center"
              v-for="docType in docsType"
              :key="docType.value"
              :value="docType.value"
              :data-test="`banker:signup:documentType:${docType.value}`"
            >
              <template v-slot:label>
                <span class="document-type-text">{{
                  translateDocsType(docType)
                }}</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="6">
          <DXATextField
            data-test="banker:signup:social_number"
            :Field="
              User.RegisterDocumentType == documentTypeEnum.socialNumber
                ? $t('cpf')
                : $t('passport')
            "
            :Text="User.SocialNumber"
            :ErrorText="errorSocialNumber"
            @text_changed="check_social_number()"
            @update="
              (value) => {
                User.SocialNumber = value;
              }
            "
            v-mask="
              User.RegisterDocumentType == documentTypeEnum.cpf ||
              User.RegisterDocumentType == documentTypeEnum.socialNumber
                ? '###.###.###-##'
                : ''
            "
            class="customTextField"
          />
        </v-col>

        <v-col cols="12" md="6">
          <DXATextField
            data-test="banker:signup:email"
            :Field="$t('email')"
            :Text="User.Email"
            :ErrorText="errorEmail"
            @text_changed="check_email()"
            @update="
              (value) => {
                User.Email = value;
              }
            "
            Type="email"
            class="customTextField align top"
          />
          <br />
        </v-col>

        <v-col cols="12" md="6">
          <v-row no-gutters>
            <v-col cols="3">
              <div :style="`height: ${FullHeight ? '80%' : 'auto'}`">
                <p
                  id="label"
                  class="dxa-text-field-label mb-1"
                  :style="`color: ${color}`"
                >
                  {{ $t("country_code") }}
                </p>
                <span class="field-header"></span>
                <v-autocomplete
                  color="black"
                  dense=""
                  filled
                  rounded
                  :rules="[
                    (v) => (!!v && User.CountryCode != null) || required(),
                  ]"
                  :items="countries"
                  item-text="name"
                  return-object
                  @change="country_code_changed"
                  v-model="User.CountryCode"
                  data-test="banker:signup:country_code"
                  class="custom-select"
                >
                  <template slot="selection" slot-scope="data">
                    <img
                      :src="data.item.flag"
                      style="width: 20px; height: auto"
                      alt=""
                    />
                    <span class="text-combo-box mx-2">{{
                      data.item.callingCodes[0]
                    }}</span>
                  </template>
                  <template slot="item" slot-scope="data">
                    <img
                      :src="data.item.flag"
                      style="width: 20px; height: auto"
                      alt=""
                    />
                    <span class="mx-2">{{ data.item.name }}</span>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>

            <v-col cols="9" class="pl-2">
              <DXATextField
                data-test="banker:signup:phone"
                :Field="$t('celphone')"
                :Text="User.CelPhone"
                :ErrorText="errorPhone"
                @text_changed="check_phone()"
                @update="
                  (value) => {
                    User.CelPhone = value;
                  }
                "
                :Mask="
                  User.CountryCode.callingCodes[0] == '55'
                    ? '(##) #####-####'
                    : ''
                "
                Type="tel"
                class="customTextField"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6">
          <DXATextField
            data-test="banker:signup:password"
            :Field="$t('password')"
            :Text="User.UserPassword"
            :ErrorText="errorPassword"
            @text_changed="check_password()"
            @update="
              (value) => {
                User.UserPassword = value;
              }
            "
            Type="password"
            class="customTextField"
          />
        </v-col>
        <v-col cols="12" md="6">
          <DXATextField
            :Field="$t('re_password')"
            :ErrorText="errorRePassword"
            @text_changed="check_re_password()"
            :Text="rePassword"
            @update="
              (value) => {
                rePassword = value;
              }
            "
            Type="password"
            data-test="banker:signup:passwordConfirm"
            class="customTextField"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <ValidateStrengthPassword :userPassword="User.UserPassword" />
        </v-col>
      </v-row>
      <div class="my-4">
        <ul>
          <li
            v-for="i in 5"
            :key="i"
            style="
              font-weight: 300;
              font-size: 12px;
              line-height: 15px;
              color: #9c9c9c;
            "
          >
            {{ $t(`password_rule_${i}`) }}
          </li>
          <!-- password min character -->
          <li
            style="
              font-weight: 300;
              font-size: 12px;
              line-height: 15px;
              color: #9c9c9c;
            "
          >
            {{ $t(`password_min_character`) }}
          </li>
        </ul>
      </div>
    </div>
    <a
      style="display: flex; align-items: center"
      class="my-4"
      @click="open_terms"
    >
      <v-checkbox
        class="dxa-checkbox"
        v-model="User.Terms"
        @click="open_terms"
        readonly
        hide-details
        color="#1A7DFF"
        data-test="banker:signup:terms"
      >
        <template v-slot:label>
          <a
            class="mb-0 ml-2"
            @click="open_terms"
            style="
              font-size: 16px;
              line-height: 20px;
              text-decoration-line: underline;
              color: #9c9c9c;
            "
          >
            {{ `${$t("read_agreed")}  ${$t("terms_use")}` }}
          </a>
        </template></v-checkbox
      >
    </a>
    <a
      style="display: flex; align-items: center"
      class="my-4"
      @click="open_confidenciality_terms"
    >
      <v-checkbox
        class="dxa-checkbox"
        v-model="User.ConfidencialityTerms"
        readonly
        hide-details
        color="#1A7DFF"
        data-test="banker:signup:confidenciality"
      >
        <template v-slot:label>
          <a
            class="mb-0 ml-2"
            style="
              font-size: 16px;
              line-height: 20px;
              text-decoration-line: underline;
              color: #9c9c9c;
            "
          >
            {{ `${$t("read_agreed")}  ${$t("terms_confidenciality")}` }}
          </a>
        </template>
      </v-checkbox>
    </a>

    <v-dialog persistent v-model="terms">
      <TermosPDFModal
        type="terms"
        :key="terms"
        :Html="contractHtmlTerms"
        :Header="$t('terms_use')"
        :Subtitle="contractAgentTerms"
        @agree="agree_terms"
        @decline="decline_terms"
        data-test="banker:signup:terms_modal"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="confidencialityTerms"
      v-if="confidencialityTerms"
    >
      <TermosConfidencialidadeModal
        type="confidenciality"
        :key="confidencialityTerms"
        :Html="contractHtmlConfidentialityTerm"
        :Header="$t('terms_confidenciality')"
        :Subtitle="contractAgentConfidentialityTerm"
        @agree="agree_confidenciality_terms"
        @decline="decline_confidenciality_terms"
      />
    </v-dialog>
    <v-row justify="start" class="ma-0 mt-2">
      <v-col cols="12" md="6" class="pa-0">
        <v-btn
          data-test="banker:signup:submit"
          @click="check_form"
          :loading="loading"
          class="mx-0 my-1 mb-3 dxa-btn-primary-blue"
          block
          style="height: 45px !important"
          ><span style="font-size: 24px">{{
            $t("agree_continue")
          }}</span></v-btn
        >
      </v-col>
    </v-row>
    <v-alert class="ma-2" v-if="fieldError" type="error">
      {{ fieldError }}
    </v-alert>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}

.dxa-text-field-label {
  color: #9c9c9c;
}

.customTextField::v-deep .dxa-text-field {
  height: 39px !important;
}

.customTextField::v-deep .dxa-text-field-label {
  color: #9c9c9c !important;
}

.document-type-text {
  color: #9c9c9c;
  font-size: 13px;
}

.custom-select::v-deep .v-input__slot {
  border-radius: 4px !important;
  height: 34px !important;
  background: #e9e9e9 !important;
}

.custom-select::v-deep .v-input__slot::before {
  border-style: none !important;
}

.text-combo-box {
  color: var(--dark);
}

.custom-select::v-deep .v-icon.v-icon {
  font-size: 20px;
  color: var(--dark);
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationService from "@/services/ValidationService";
import DXATextField from "@/components/dxa/TextField/DxaTextField";
import TermosPDFModal from "@/components/dxa/pdfModal/PdfModal";
import TermosConfidencialidadeModal from "@/components/dxa/pdfModal/PdfModal";
import { mapGetters } from "vuex";
import moment from "moment";
import UserService from "@/services/UserService";
import { contractTypesEnum } from "@/shared/enums/ContractTypesEnum.js";
import countriesJson from "@/utils/countries.json";
import { DocumentTypeEnum } from "@/shared/enums/DocumentType.js";
import mixpanel from "mixpanel-browser";
import { checkPasswordMinCharacter } from "@/shared/helpers/validatePassword";
import ValidateStrengthPassword from "@/components/dxa/Password/ValidateStrengthPassword";

export default {
  name: "BankerInformation",
  components: {
    DXATextField,
    TermosPDFModal,
    TermosConfidencialidadeModal,
    ValidateStrengthPassword,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    vs: new ValidationService(),
    valid: true,
    lazy: false,
    alert: null,
    fieldError: null,
    errorEmail: null,
    checkPasswordMinCharacter,
    errorSocialNumber: null,
    errorPhone: null,
    errorPassword: null,
    errorRePassword: null,
    rePassword: null,
    termsOpened: false,
    terms: false,
    confidencialityTermsOpened: false,
    confidencialityTerms: false,
    loading: false,
    contractTermHtml: "",
    contractTermAgent: "",
    contractConfidentialityTermHtml: "",
    contractConfidentialityTermAgent: "",
    userService: new UserService(),
    countries: countriesJson,
    nationalitySelected: null,
    docsType: [{ text: "cpf", value: 3 }],
    documentTypeEnum: DocumentTypeEnum,
    countryCode: null,
  }),
  async created() {
    if (this.User.Nationality == null) {
      this.nationalitySelected = this.countries.filter(
        (x) => x.name == "Brazil"
      )[0];
      this.User.Nationality = "Brazil";
    } else {
      this.nationalitySelected = this.countries.filter(
        (x) => x.name == this.User.Nationality
      )[0];
    }
    if (this.User.RegisterDocumentType == this.documentTypeEnum.select) {
      this.User.RegisterDocumentType = this.documentTypeEnum.socialNumber;
    }
    if (this.countryCode == null || this.countryCode == "") {
      this.countryCode =
        this.countries.filter((x) =>
          x.callingCodes.includes(this.User.CountryCode)
        )[0] || this.countries.filter((x) => x.callingCodes.includes("55"))[0];

      this.User.CountryCode = this.countryCode;
    }
    this.verifyUserNationality(this.nationalitySelected);

    this.fetchAllContractsHtml(this.$i18n.locale);
  },
  props: {
    User: Object,
  },
  computed: {
    ...mapGetters({
      configs: "get_partner",
    }),
    fields_filled() {
      return (
        this.User.Name &&
        this.User.LastName &&
        this.User.SocialNumber &&
        this.User.Email &&
        this.User.CelPhone &&
        this.User.UserPassword &&
        this.rePassword &&
        this.User.Terms &&
        this.User.ConfidencialityTerms &&
        this.User.RegisterDocumentType &&
        this.User.Nationality &&
        this.User.CountryCode
      );
    },
    errors() {
      return (
        this.errorEmail ||
        this.errorSocialNumber ||
        this.errorPhone ||
        this.errorPassword ||
        this.errorRePassword
      );
    },
    contractHtmlTerms() {
      return this.contractTermHtml;
    },
    contractAgentTerms() {
      return this.contractTermAgent;
    },
    contractHtmlConfidentialityTerm() {
      let fullName = "";
      if (this.User.Name && this.User.LastName) {
        fullName = this.User.Name + " " + this.User.LastName;
      }
      return this.contractConfidentialityTermHtml
        .replaceAll("${FULLNAME}", fullName)
        .replace("${SOCIALNUMBER}", this.User.SocialNumber || "")
        .replace("${EMAIL}", this.User.Email || "")
        .replace("${DAY}", moment().format("DD"))
        .replace("${MONTH}", moment().locale(localStorage.lang).format("MMM"))
        .replace("${YEAR}", moment().format("YYYY"))
        .replace("${PARTNER}", this.configs.name || "")
        .replace("${PARTNER_REGISTERDATE}", this.configs.registerDate || "");
    },
    contractAgentConfidentialityTerm() {
      return this.contractConfidentialityTermAgent;
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
  },

  methods: {
    open_terms() {
      this.User.Terms = false;
      this.terms = true;
      this.termsOpened = true;
    },
    agree_terms() {
      this.User.Terms = true;
      this.terms = false;
    },
    decline_terms() {
      this.User.Terms = false;
      this.terms = false;
    },
    async open_confidenciality_terms() {
      await this.fetchConfidentialityTerm();
      this.User.ConfidencialityTerms = false;
      this.confidencialityTerms = true;
      this.confidencialityTermsOpened = true;
    },
    agree_confidenciality_terms() {
      this.User.ConfidencialityTerms = true;
      this.confidencialityTerms = false;
    },
    decline_confidenciality_terms() {
      this.User.ConfidencialityTerms = false;
      this.confidencialityTerms = false;
    },
    check_phone() {
      this.errorPhone = null;
      if (
        this.User.CountryCode.callingCodes[0] == "55" &&
        !this.vs.phone_valid(this.User.CelPhone)
      ) {
        this.errorPhone = this.$t("phone_invalid");
      }
    },
    async check_social_number() {
      // se o tipo for diferente de passaporte, que dizer que é cpf ou socialNumber, então só permitimos dígitos.
      // se for igual a passaporte, permitimos letras e dígitos.
      let onlyNumbers =
        this.User.RegisterDocumentType !== this.documentTypeEnum.passport;

      this.errorSocialNumber = null;
      if (
        this.User.RegisterDocumentType == this.documentTypeEnum.socialNumber &&
        !this.vs.social_number_valid(this.User.SocialNumber)
      ) {
        this.errorSocialNumber = this.$t("format_invalid");
        return;
      }
      if (
        await this.vs.check_if_social_number_exists(
          this.User.Id,
          this.User.SocialNumber,
          "banker",
          onlyNumbers
        )
      ) {
        this.errorSocialNumber = this.$t("social_number_exists");
      }
    },
    async check_email() {
      this.errorEmail = null;
      if (!this.vs.accent_valid(this.User.Email)) {
        this.errorEmail = this.$t("accent_char");
        return;
      }
      if (!this.vs.email_valid(this.User.Email)) {
        this.errorEmail = this.$t("format_invalid");
        return;
      }
      if (
        await this.vs.check_if_email_exists(
          this.User.Id,
          this.User.Email,
          false
        )
      ) {
        this.errorEmail = this.$t("user_exists");
      }
    },
    check_re_password() {
      this.errorRePassword = null;
      if (!this.vs.passwordsMatch(this.User.UserPassword, this.rePassword)) {
        this.errorRePassword = this.$t("pswds_dnt_match");
        return;
      }
    },
    check_password() {
      this.errorPassword = null;
      if (this.User.UserPassword.length < 8) {
        this.errorPassword = this.$t("min_char");
        return;
      }
      if (!this.vs.number_required(this.User.UserPassword)) {
        this.errorPassword = this.$t("number_required");
        return;
      }
      if (!this.vs.accent_valid(this.User.UserPassword)) {
        this.errorPassword = this.$t("accent_char");
        return;
      }
      if (!this.vs.uppercase_required(this.User.UserPassword)) {
        this.errorPassword = this.$t("uppercase_required");
        return;
      }
      if (!this.vs.lowercase_required(this.User.UserPassword)) {
        this.errorPassword = this.$t("lowercase_required");
        return;
      }
      if (!this.vs.special_required(this.User.UserPassword)) {
        this.errorPassword = this.$t("special_required");
        return;
      }
      if (!this.checkPasswordMinCharacter(this.User.UserPassword)) {
        this.errorPassword = this.$t("password_min_character");
        return;
      }
    },
    async check_form() {
      this.loading = true;
      this.fieldError = null;

      // prevenindo que a nova senha não fique diferente do confirmar senha
      this.check_re_password();

      if (this.fields_filled && !this.errors) {
        this.User.CelPhone = `+${this.User.CountryCode.callingCodes[0]}${this.User.CelPhone}`;
        await this.apiService
          .postRequest("banker/register", this.User)
          .then((result) => {
            const parsedBankerData = JSON.parse(result.message);

            this.$emit("created", parsedBankerData, this.User);

            // EMITINDO O EVENTO QUANDO TIVER PREENCHIDO TODO O FORM E PASSA PARA O PRÓXIMO STEP
            mixpanel.track("BankerRegister_Start", {
              partner_id: this.User.PartnerId,
              user_name: this.User.Name,
              email: this.User.Email,
              phone: this.User.CelPhone,
              nationality: this.User.Nationality,
              user_document_id: this.User.SocialNumber,
            });
          })
          .catch((err) => {
            this.$emit("error", err.body.message);
          });
      } else if (!this.fields_filled) {
        this.fieldError = this.$t("all_fields_required");
      }
      this.loading = false;
    },
    async fetchAllContractsHtml(language) {
      let contractTerm = await this.userService.getContractHtml(
        contractTypesEnum.TERMSOFUSE,
        language,
        this.User
      );
      this.contractTermHtml = contractTerm.content;
      this.contractTermAgent = contractTerm.agent;
      await this.fetchConfidentialityTerm();
    },

    async fetchConfidentialityTerm() {
      let contractConfidentialityTerm = await this.userService.getContractHtml(
        contractTypesEnum.CONFIDENTIALITYTERMS,
        !this.User.RegisterDocumentType ||
          this.User.RegisterDocumentType === this.documentTypeEnum.socialNumber
          ? "pt"
          : "en",
        this.User
      );
      this.contractConfidentialityTermHtml =
        contractConfidentialityTerm.content;
      this.contractConfidentialityAgent = contractConfidentialityTerm.agent;
    },

    nationality_code_changed(e) {
      this.verifyUserNationality(e);
      if (this.nationalitySelected.name) {
        this.User.Nationality = this.nationalitySelected.name;
        this.$emit("getPortfolioOptions", this.nationalitySelected.name);
      }
    },
    verifyUserNationality(e) {
      if (e.alpha3Code !== "BRA") {
        this.docsType = [
          { text: "cpf", value: this.documentTypeEnum.socialNumber },
          { text: "passport", value: this.documentTypeEnum.passport },
        ];
      } else {
        this.docsType = [
          { text: "cpf", value: this.documentTypeEnum.socialNumber },
        ];
      }
    },
    translateDocsType(item) {
      return this.$t(item.text);
    },
    country_code_changed() {
      if (this.User.CelPhone) {
        this.User.CelPhone = "";
      }
    },
  },
};
</script>
